
import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import { Container, Row,Col } from "react-bootstrap";
import './Bannere.css'
import { useAppContext } from '../Context/AppContext';
import { useContext } from 'react';

import {Link} from 'react-router-dom'

 function Bannere(props) {
    // const { articleurl, categorytitle, categoryurl, date, image, title } = props.data;
    // const { BASE_PATH } = useContext(useAppContext);
    return (
        <section className="storieslider p-2">
         
            <Container >
             {/* <Row>
                <div className='whitetitle'>
                    <h1>E News  </h1>
                </div>
                </Row> */}

                <Row>
                 <Col lg={6} md={6} sm={12} xs={12}  >
                  
                   <div className=" p-2 d-flex justify-content-center align-content-center"> 
<Link to="https://www.mobilemasala.com/movies/Shaitaan-trailer-out-Guess-which-film-is-Ajay-Devgn-R-Madhavans-film-inspired-from">
<video loop={true} autoPlay="autoplay"  muted   playsInline className="vid1">
                                            {/* <source src={data.video} type="video/mp4" /> */}
                                            <source src="/MME1.mp4" type="video/mp4"   />

                                        </video>
{/* <img src="/MME1.gif" alt="banner" className="bannere" /> */}
</Link>
           
  </div>       
                 
                    </Col>
                <Col lg={6} md={6} sm={12} xs={12}  >
                  
   <div className=" p-2 d-flex justify-content-center align-content-center "> 
<Link to="https://www.mobilemasala.com/movie-review/Srkk-Review-Too-Many-Abs-Too-Little-Logic-Vidyut-Jammwal-And-Arjun-Rampal-Shot-For-Khatron-Ke-Khiladi-But-Called-The-Movie">
<video loop={true} autoPlay="autoplay"  muted playsInline  className="vid1">
                                            {/* <source src={data.video} type="video/mp4" /> */}
                                            <source src="/MME2.mp4" type="video/mp4"  />

                                        </video>
{/* <img src="/MME2.gif" alt="banner" className="bannere" /> */}
</Link>
           
  </div>          
                 
                    </Col>
                   
                </Row>
              
            </Container>
        </section>
    );
}


export default Bannere
