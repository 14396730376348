  import React, { useState, useEffect } from "react";
  import Menu from "./Menu/Menu";
  import 'bootstrap/dist/css/bootstrap.min.css';
  import Cookies from "js-cookie";
  import { Route, Routes } from "react-router-dom";
  import './App.css';
  import Home from "./Home/Home";
  import Footer from "./Footer/Footer";
  import Error from "./Error";
  import ContactUs from "./OtherPages/ContactUs";
  import Category from "./Category/Category";
  import Article from "./Article/Article";
  import { FirebaseToken } from "./Notification/FirebaseToken";
  import { getToken } from "firebase/messaging";
  import YoutubeVIdeosCategory from "./Category/YoutubeVIdeosCategory";
  import AmpStoriesCard from "./AmpStories/AmpStoriesCard";
  import { HelmetProvider } from "react-helmet-async";
  import OtherPages from "./OtherPages/OtherPages";
  import VerticalAd from "./Ads/VerticalAd";
  import NotificationForm from './NotificationForm'
 


  function App() {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    if (!Cookies.get('lg')) {
      Cookies.set('lg', 'en');
    }

    const fetchIPData = async () => {
      const response = await fetch("https://api.ipify.org?format=json");
      const res = await response.json();
      fetchData(res.ip);
    }

    const fetchData = async (ipdata = 'No IP') => {
        //  const fetchData = async () => {
          // var ipAddress="14.194.40.178";

          // const response = await fetch( `http://172.22.9.91:9093/en?ip=${ipAddress}`, {

        const response = await fetch("https://api.mobilemasala.com/" + Cookies.get('lg') + "?ip=" + ipdata, {
        method: 'POST'
      })
      const finaldata = await response.json()
      setData(finaldata.data)
      setIsLoading(false);
      if (finaldata.data.companydata.language === 'en') {
        document.body.classList.add('en');
        document.body.classList.remove('te');
        document.body.classList.remove('hi');
      } else if (finaldata.data.companydata.language === 'tl') {
        document.body.classList.add('te');
        document.body.classList.remove('en');
        document.body.classList.remove('hi');
      } else {
        document.body.classList.remove('te');
        document.body.classList.remove('en');
        document.body.classList.add('hi');
      }
    }


  
  
    useEffect(() => {
       fetchIPData();
      const tokenSent = localStorage.getItem('tokenSent'); // Check if the token has been sent
  
      if (!tokenSent) {
        requestPermission();
      }
      
    }, []);
  
    async function requestPermission() {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(FirebaseToken);
        sendTokenToApi(token);
        console.log( token);
        localStorage.setItem('tokenSent', 'true');
      } else if (permission === "denied") {
        // alert("You denied the notification");
      }
    }
  
    async function sendTokenToApi(token) {
      try {
        //  const decodedToken = jwt.decode(token, { complete: true });

         const base64Token = btoa(JSON.stringify(token));
        //  console.log( 'sfgg' + base64Token);
        //  const vase=atob(base64Token);
        //  console.log(vase);
        const response = await fetch(`https://api.mobilemasala.com/mmnotification-api/insertToken?token=${base64Token}`, {
          // const response = await fetch(`http://172.22.9.91:9093/mmnotification-api/insertToken?token=${base64Token}`, {

          
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          }
        });
  
        if (response.ok) {
          console.log("Token sent to the API successfully.");
        } else {
          console.error("Failed to send token to the API.");
        }
      } catch (error) {
        console.error("API request error:", error);
      }
    }

    if (isLoading) {
      return (
        <div className="d-flex align-items-center justify-content-center vh-100" >
          <p>Loading</p>
        </div>
      )
    }

    if (!data.companydata) {
      return (<><div className='d-flex align-items-center justify-content-center vh-100'>
        <p>No Data Found</p>
      </div></>)
    }

    const { companydata, navdata, otherpages, vads = '' } = data;



    

    return (
      <>
        <HelmetProvider>
          <Menu navbardata={navdata} companydetails={companydata} />
          {/* {vads && <>
          <div>
            <VerticalAd adslot={vads[0].adslot} adsize={vads[0].adsizes} adnumber={vads[0].adnumber} postion='left' />
            <VerticalAd adslot={vads[1].adslot} adsize={vads[1].adsizes} adnumber={vads[1].adnumber} postion='right' />
          </div>
          </>
          } */}

          <Routes>
           
            <Route path="/" element={<Home />} />

            <Route path="/search/:searchdata" element={<Category />} />
            <Route path="/सर्च/:searchdata" element={<Category />} />
            <Route path="/సెర్చ్/:searchdata" element={<Category />} />

            <Route path="/:categoryname" element={<Category />} />

            <Route path="/:categoryname/:articlename" element={<Article />} />

            <Route path="/videos" exact={true} element={<YoutubeVIdeosCategory />} />
            <Route path="/वीडियोस" exact={true} element={<YoutubeVIdeosCategory />} />
            <Route path="/వీడియోస్" exact={true} element={<YoutubeVIdeosCategory />} />
            <Route path="/RJ-Suneetha-కబుర్లు" exact={true} element={<YoutubeVIdeosCategory />} />

            <Route path="/paparazzi" exact={true} element={<YoutubeVIdeosCategory />} />
            <Route path="/पेप्पराज़ी" exact={true} element={<YoutubeVIdeosCategory />} />
            <Route path="/ఫిల్మ్-ఈవెంట్స్" exact={true} element={<YoutubeVIdeosCategory />} />

            <Route path="/trailers" exact={true} element={<YoutubeVIdeosCategory />} />
            <Route path="/ट्रेलर" exact={true} element={<YoutubeVIdeosCategory />} />
            <Route path="/ట్రైలర్స్" exact={true} element={<YoutubeVIdeosCategory />} />

            <Route path="/photo-stories/:storiename" exact={true} element={<AmpStoriesCard />} />
            <Route path="/फोटो-स्टोरी/:storiename" exact={true} element={<AmpStoriesCard />} />
            <Route path="/వెబ్-స్టోరీ/:storiename" exact={true} element={<AmpStoriesCard />} />

            <Route path="/horoscope/:storiename" exact={true} element={<AmpStoriesCard />} />
            <Route path="/राशीफल/:storiename" exact={true} element={<AmpStoriesCard />} />
            <Route path="/రాశిఫలాలు/:storiename" exact={true} element={<AmpStoriesCard />} />

            <Route path="/contact-us" exact={true} element={<ContactUs />} />

            <Route path="/privacy-policy" exact={true} element={<OtherPages data={otherpages.privacypolicypage} title={"Privacy Policy"} />} />
            <Route path="/terms-of-use" exact={true} element={<OtherPages data={otherpages.termsandconditionspage} title={"Terms of Use"} />} />
            <Route path="/Notification" element={<NotificationForm />} />

            <Route path="*" element={<Error />} />
          </Routes>
          <Footer companydetails={companydata} />
        </HelmetProvider>
      </>
    )
  }

  export default App;
