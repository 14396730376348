import React, { useRef,useEffect } from 'react';
import { Button, Col, InputGroup } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import facebook from '../logos/facebook.png'
import instagram from '../logos/instagram.e719d8b29f452a16302d.webp';
import threads from '../logos/threads.1ddabb9d6e62eccf4743.webp';
import youtube from '../logos/youtube.png'
import whatsapp from '../logos/whatsapp.bf5aa8e8b9ebe6e342ad.webp';
import './Footer.css'

const Footer = (props) => {
    const { companydetails } = props;
    const subscribe = useRef();

    const sendSubscribe = async () => {
        if (subscribe.current.value != '') {
            const data = await fetch('https://api.mobilemasala.com/user-api/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    "email": subscribe.current.value
                })
            })
            const res = await data.json();
            if (res.status == 'SUCCESS') {
                alert('Subscription Successfully');
            } else {
                alert('Somthing Wrong');
            }
            subscribe.current = '';
            window.location.reload();
        }
    }
    // useEffect(() => {
  
    //     window.googletag = window.googletag || { cmd: [] };
    //     window.googletag.cmd.push(function () {
    //      window.googletag.defineSlot('/22387492205,22578111928/mobilemasala.com.Banner0.1695791767', [[300, 50], [300, 100], [320, 50], [320, 100], [728, 90], "fluid"], 'gpt-passback-Sticky').addService(window.googletag.pubads());
    //         window.googletag.enableServices();
    //         window.googletag.pubads().set('page_url', 'www.mobilemasala.com');
    //         window.googletag.display('gpt-passback-Sticky');
    //         window.googletag.pubads().addEventListener('slotOnload', function (event) {
    //             var slotId = event.slot.getSlotElementId();
    //             if (slotId === "gpt-passback-Sticky") {
    //                 document.getElementById("sticky").style.visibility = 'visible';
    //                 document.getElementById("ad_close").onclick = function () {
    //                     document.getElementById("sticky").remove();
    //                 };
    //             }
    //         });
    //     });
    
    
    // }, []);
    

    return (
        <>
            {/* <div
  id="sticky"
  style={{
    position: "fixed",
    right: 0,
    bottom: 0,
    margin: "0 auto",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderTop: "2px solid rgba(225, 225, 225, 0.8)",
    visibility: "hidden",
    zIndex: 99999999
  }}
>
  <button
    id="ad_close"
    style={{
      color: "#000",
      backgroundColor: "hsla(0,0%,100%,.8)",
      border: "hsla(0,0%,100%,.8)",
      position: "absolute",
      left: 0,
      top: "-45px"
    }}
  >
    x
  </button>
  <div
    id="gpt-passback-Sticky"
    style={{
      minWidth: 300,
      minHeight: 50,
      textAlign: "center",
      display: "flex",
      justifyContent: "center"
    }}
  ></div>
</div> */}
            <section className='footer'>
                <Container >
                    <Row>
                        <Col lg={5} md={12} sm={12} xs={12} className='text-center px-3 py-2'>

                            <h1>{companydetails.companyname}</h1>
                            <h6>Want to know what we're up to ? <br></br> Sign up for the News Letter</h6>
                            <div className='subscribe'>
                                <InputGroup>
                                    <Form.Control ref={subscribe}
                                        placeholder="Email (or) Phone Number"
                                        aria-label="Email (or) Phone Number"
                                    />
                                    <Button variant="outline-secondary" onClick={() => sendSubscribe()}>Subscribe</Button>
                                </InputGroup>
                            </div>
                            <div className='icons'>
                                <Link to={companydetails.companyfacebook} target='_blank'>
                                    <img src={facebook} alt="Facebook" />
                                </Link>
                                <Link to={companydetails.companyinstagram} target='_blank'>
                                    <img src={instagram} alt="instagram" />
                                </Link>
                                <Link to={companydetails.companythreads} target='_blank'>
                                    <img src={threads} alt="threads" />
                                </Link>
                                <Link to={companydetails.companyyoutube} target='_blank'>
                                    <img src={youtube} alt="youtube" />
                                </Link>
                                <Link to={companydetails.companywhatsapp} target='_blank'>
                                    <img src={whatsapp} alt="youtube" />
                                </Link>
                            </div>
                        </Col>
                        <Col lg={3} md={12} sm={12} xs={12} className='text-left px-3 py-2'>
                            <h5>Information</h5>
                            <ul>
                                <li>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-use">Terms of Use</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col lg={4} md={12} sm={12} xs={12} className='text-left px-3 py-2'>
                            <h5>Contact</h5>
                            <ul>
                                <li>Phone :&#160;
                                    <a href={`tel:${companydetails.companyphone}`}>
                                        {companydetails.companyphone}
                                    </a>
                                </li>
                                <li>Email Id :&#160;
                                    <a href={`mailto:${companydetails.companyemailid}`}>
                                        {companydetails.companyemailid}
                                    </a>
                                </li>
                                <li>Address :&#160;
                                    {companydetails.companyaddress}
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <Row className='text-center bg-black m-0 p-0'><p className=' m-0 text-white reserve'>© 2023 {companydetails.companyname}. All Rights Reserved</p></Row>
            </section >
        </>
    );
}

export default Footer;