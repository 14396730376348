import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from "../WebButton/ViewMoreButton";
import { useParams } from 'react-router-dom';
import './Article.css'
import HorizontalAd from '../Ads/HorizontalAd';
import RealtedArticel from '../RelatedArticel/RelatedArticel';
import WhiteStorieCard from '../StorieCards/White/WhiteStorieCard';
import { useAppContext } from '../Context/AppContext';
import Cookies from 'js-cookie';
import parse from 'html-react-parser';
import Meta from '../Meta/Meta';
import Share from '../Share/Share';
import youtube from '../logos/youtube.png'

const Article = () => {


    if (!Cookies.get('lg')) {
        Cookies.set('lg', 'en');
    }

    const { BASE_PATH } = useContext(useAppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [relatedtitle, setRelatedtitle] = useState();
    const [trendingtitle, setTrendingtitle] = useState();
    const [type, setType] = useState();
    const { categoryname, articlename } = useParams();
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [relativeTime, setRelativeTime] = useState("");



    const commentref = useRef();

    const language = Cookies.get('lg');

    useEffect(() => {
        if (categoryname == 'videos' || categoryname == 'paparazzi' || categoryname == 'trailers' || categoryname == 'वीडियोस' || categoryname == 'पेप्पराज़ी' || categoryname == 'ट्रेलर' || categoryname == 'వీడియోస్' || categoryname == 'ఫిల్మ్-ఈవెంట్స్' || categoryname == 'ట్రైలర్స్' || categoryname == 'RJ-Suneetha-కబుర్లు') {
            fetchData('videos');
            setType('videos');
        } else {
            fetchData('article');
            setType('article');
        }
    }, [])

   
    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();

        return `${day.toString().padStart(2, '0')}/${month
            .toString()
            .padStart(2, '0')}/${year}`;
    };

    const formatTime = (date) => {
        const options = {
            hour: 'numeric',
            minute: 'numeric'
        };
        return date.toLocaleTimeString(undefined, options);
    };


    const fetchData = async (artcletype) => {
        const response = await fetch("https://api.mobilemasala.com/" + language + "/articlepage?type=" + artcletype + "&category=" + categoryname + "&article=" + articlename, {
            method: "POST"
        })
        const finaldata = await response.json()
        setData(finaldata.data)
        setIsLoading(false);

        if (language === 'en') {
            setRelatedtitle('Related');
            setTrendingtitle('Trending');
        }

        if (language === 'tl') {
            setRelatedtitle('రిలేటెడ్');
            setTrendingtitle('ట్రెండింగ్');
        }

        if (language === 'hi') {
            setRelatedtitle('रिलेटेड');
            setTrendingtitle('ट्रेंडिंग');
        }

        if (finaldata.data.articledata != null) {
            setCurrentDateTime(new Date(finaldata.data.articledata.date));
        }

    }

    
    const getTimeDifference = (currentDateTime, articleDateTime) => {
        const elapsed = currentDateTime.getTime() - articleDateTime.getTime();
        const seconds = Math.floor(elapsed / 1000);
    
        if (seconds < 60) {
            return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
        }
    
        const minutes = Math.floor(seconds / 60);
        if (minutes < 60) {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        }
    
        const hours = Math.floor(minutes / 60);
        if (hours < 24) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        }
    
        const days = Math.floor(hours / 24);
        if (days < 30) {
            return `${days} ${days === 1 ? 'day' : 'days'} ago`;
        }
    
        const months = Math.floor(days / 30);
        if (months < 12) {
            return `${months} ${months === 1 ? 'month' : 'months'} ago`;
        }
    
        const years = Math.floor(months / 12);
        return `${years} ${years === 1 ? 'year' : 'years'} ago`;
    };
    

    useEffect(() => {
        if (data.articledata != null) {
            const articleDateTime = new Date(data.articledata.date);
            const relative = getTimeDifference(new Date(), articleDateTime);
            setRelativeTime(relative);
            console.log('Relative Time:', relative); // Add this line
        }
    }, [data]);
    
    console.log(relativeTime);
    const sendComment = async () => {
        if (commentref.current.value != '') {
            const data = await fetch('https://api.mobilemasala.com/user-api/post-comment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    "postid": postid,
                    "categorytitle": categorytitle,
                    "comment": commentref.current.value
                })
            })
            const res = await data.json();
            if (res.status == 'SUCCESS') {
                alert('Comment Send Successfully');
            } else {
                alert('Somthing Wrong');
            }
            commentref.current = '';
            window.location.reload();
        }
    }

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100" >
                <p>Loading</p>
            </div>
        )
    }

    if (data.articledata === null) {
        return (
            <><div className='d-flex align-items-center justify-content-center vh-100'>
                <p>No Data Found</p>
            </div></>
        )
    }
    const { articledata, ads = 0 } = data;
    const { image, description, title, categorytitle, categoryurl, date, video, comments, views, postid, hashtags } = articledata;

    const final_data = () => {
        if (type === 'article') {
            return (
                <>
                    <section className='article pt-1'>
                        <Container>
                            <Row className='article-row'>
                                <Col>
                                    <img src={image} alt={title} />
                                    <div className='description pt-2'>
                                        <div className='whitetitle '>
                                            <h1>{title}</h1>
                                        </div>
                                       

                                         <p>{relativeTime} | {views} Views</p> 

                                        {/* <p>{formatDate(currentDateTime)}  {formatTime(currentDateTime)} | {views} Views</p>    */}
                                                                             <div>
                                            <div>
                                                {description && parse(description)}
                                                {hashtags != null ? (<>
                                                    {hashtags.map((data, index) => {
                                                        return <a href={`${BASE_PATH}search/${data.keyword}`}># {data.title}&nbsp;&nbsp;&nbsp;&nbsp; </a>
                                                    })}

                                                </>) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='p-3 border rounded my-3 float-none'>
                                        <h6>Top Comments</h6>
                                        <InputGroup className="mb-3">
                                            <Form.Control aria-label="comment" ref={commentref} placeholder='Write a comment' />
                                            <InputGroup.Text>
                                                <svg onClick={() => sendComment()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" className="bi bi-send" viewBox="0 0 16 16">
                                                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                </svg>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        {
                                            comments.length > 0 ? (
                                                comments.map((data, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} className='d-flex'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                                </svg> <p className='ps-2'>{data.comment}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            ) : (
                                                <p>There are no comments in this article yet. Be first to post one!</p>
                                            )
                                        }
                                    </div>
                                    <Share shareUrl={decodeURI(window.location.href)} title={title} className="text-center" />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    {ads.length > 0 ? (
                        <>
                            <HorizontalAd adslot={ads[1].adslot} adsizes={ads[1].adsizes} adnumber={ads[1].adnumber} />
                        </>
                    ) : (<></>)
                    }
                    <RealtedArticel title={relatedtitle} url={categoryurl}>{data.relatedarticledata}</RealtedArticel>
                    {ads.length > 0 ? (
                        <>
                            <HorizontalAd adslot={ads[2].adslot} adsizes={ads[2].adsizes} adnumber={ads[2].adnumber} />
                        </>
                    ) : (<></>)
                    }
                    <WhiteStorieCard title={trendingtitle} url={categoryurl}>{data.categoryarticle}</WhiteStorieCard>
                    {ads.length > 0 ? (
                        <>
                            <HorizontalAd adslot={ads[3].adslot} adsizes={ads[3].adsizes} adnumber={ads[3].adnumber} />
                        </>
                    ) : (<></>)
                    }
                </>
            )
        } else {
            return (
                <>
                    <section className='article pt-1'>
                        <Container>
                            <Row className='article-row'>
                                <Col>
                                    <div className='video-section mb-3'>
                                        <iframe id="ytplayer" height="100%" width="100%" type="text/html"
                                            src={video + '?autoplay=1&loop=1'} frameBorder="0" allow="autoplay" />
                                    </div>
                                    <div className='description pt-2'>
                                        <div className='whitetitle '>
                                            <h1>{title}</h1>
                                        </div>
                                        <p>{date}</p>
                                        <div>
                                            {description && parse(description)}
                                            {hashtags != null ? (<>
                                                {hashtags.map((data, index) => {
                                                    return <a href={`${BASE_PATH}search/${data.keyword}`}># {data.title}&nbsp;&nbsp;&nbsp;&nbsp; </a>
                                                })}

                                            </>) : null}
                                        </div>
                                    </div>
                                    <div className='p-3 border rounded my-3'>
                                        <h6>Top Comments</h6>

                                        <InputGroup className="mb-3">
                                            <Form.Control aria-label="comment" placeholder='Write a comment' />
                                            <InputGroup.Text>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" className="bi bi-send" viewBox="0 0 16 16">
                                                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                </svg>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        {
                                            comments.length > 0 ? (
                                                comments.map((data, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} className='d-flex'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                                </svg> <p className='ps-2'>{data.comment}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            ) : (
                                                <p>There are no comments in this article yet. Be first to post one!</p>
                                            )
                                        }
                                    </div>
                                    <Share shareUrl={window.location.href} title={title} className="text-center" />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    {ads.length > 0 ? (
                        <>
                            <HorizontalAd adslot={ads[1].adslot} adsizes={ads[1].adsizes} adnumber={ads[1].adnumber} />
                        </>
                    ) : (<></>)
                    }
                    <section className="relatedarticel article_youtube">
                        <Container>
                            <Row>
                                <div className='whitetitle'>
                                    <h1>{relatedtitle}</h1>
                                </div>
                            </Row>
                            <Row>
                                <Col lg={6} md={12} sm={12} xs={12}  >
                                    <Card >
                                        <div className='position-relative'>
                                            <Card.Img variant="top" src={data.relatedarticledata[0].image} alt={title} loading="lazy" />
                                            <Card.Link href={`${BASE_PATH}${data.relatedarticledata[0].categoryurl}/${data.relatedarticledata[0].articleurl}`} className='icon'>
                                                <img src={youtube} alt="youtube" />
                                            </Card.Link>
                                        </div>
                                        <Card.Body>
                                            <Card.Text className='card-category'>{data.relatedarticledata[0].categorytitle} </Card.Text>
                                            <Card.Link href={`${BASE_PATH}${data.relatedarticledata[0].categoryurl}/${data.relatedarticledata[0].articleurl}`}>
                                                <Card.Title className="word-line-limit3 first-artical-title">{data.relatedarticledata[0].title}</Card.Title>
                                            </Card.Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6} md={12} sm={12} xs={12} >
                                    <Row>
                                        <Col lg={6} md={6} sm={6} xs={6} >
                                            <Card >
                                                <div className='position-relative'>
                                                    <Card.Img variant="top" src={data.relatedarticledata[1].image} alt={title} loading="lazy" />
                                                    <Card.Link href={`${BASE_PATH}${data.relatedarticledata[1].categoryurl}/${data.relatedarticledata[1].articleurl}`} className='icon'>
                                                        <img src={youtube} alt="youtube" />
                                                    </Card.Link>
                                                </div>
                                                <Card.Body>
                                                    <Card.Text className='card-category'>{data.relatedarticledata[1].categorytitle} </Card.Text>
                                                    <Card.Link href={`${BASE_PATH}${data.relatedarticledata[1].categoryurl}/${data.relatedarticledata[1].articleurl}`}>
                                                        <Card.Title className='word-line-limit'>{data.relatedarticledata[1].title}</Card.Title>
                                                    </Card.Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={6} >
                                            <Card >
                                                <div className='position-relative'>
                                                    <Card.Img variant="top" src={data.relatedarticledata[2].image} alt={title} loading="lazy" />
                                                    <Card.Link href={`${BASE_PATH}${data.relatedarticledata[2].categoryurl}/${data.relatedarticledata[2].articleurl}`} className='icon'>
                                                        <img src={youtube} alt="youtube" />
                                                    </Card.Link>
                                                </div>
                                                <Card.Body>
                                                    <Card.Text className='card-category'>{data.relatedarticledata[2].categorytitle} </Card.Text>
                                                    <Card.Link href={`${BASE_PATH}${data.relatedarticledata[2].categoryurl}/${data.relatedarticledata[2].articleurl}`}>
                                                        <Card.Title className='word-line-limit'>{data.relatedarticledata[2].title}</Card.Title>
                                                    </Card.Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={6} sm={6} xs={6} >
                                            <Card >
                                                <div className='position-relative'>
                                                    <Card.Img variant="top" src={data.relatedarticledata[3].image} alt={title} loading="lazy" />
                                                    <Card.Link href={`${BASE_PATH}${data.relatedarticledata[3].categoryurl}/${data.relatedarticledata[3].articleurl}`} className='icon'>
                                                        <img src={youtube} alt="youtube" />
                                                    </Card.Link>
                                                </div>
                                                <Card.Body>
                                                    <Card.Text className='card-category'>{data.relatedarticledata[3].categorytitle} </Card.Text>
                                                    <Card.Link href={`${BASE_PATH}${data.relatedarticledata[3].categoryurl}/${data.relatedarticledata[3].articleurl}`}>
                                                        <Card.Title className='word-line-limit'>{data.relatedarticledata[3].title}</Card.Title>
                                                    </Card.Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={6} >
                                            <Card >
                                                <div className='position-relative'>
                                                    <Card.Img variant="top" src={data.relatedarticledata[4].image} alt={title} loading="lazy" />
                                                    <Card.Link href={`${BASE_PATH}${data.relatedarticledata[4].categoryurl}/${data.relatedarticledata[4].articleurl}`} className='icon'>
                                                        <img src={youtube} alt="youtube" />
                                                    </Card.Link>
                                                </div>
                                                <Card.Body>
                                                    <Card.Text className='card-category'>{data.relatedarticledata[4].categorytitle} </Card.Text>
                                                    <Card.Link href={`${BASE_PATH}${data.relatedarticledata[4].categoryurl}/${data.relatedarticledata[4].articleurl}`}>
                                                        <Card.Title className='word-line-limit'>{data.relatedarticledata[4].title}</Card.Title>
                                                    </Card.Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <ViewMoreButtonMobile redirect={data.relatedarticledata[0].categoryurl} />
                        </Container>
                    </section >

                    {ads.length > 0 ? (
                        <>
                            <HorizontalAd adslot={ads[2].adslot} adsizes={ads[2].adsizes} adnumber={ads[2].adnumber} />
                        </>
                    ) : (<></>)
                    }
                    <WhiteStorieCard title={trendingtitle} url={categoryurl}>{data.categoryarticle}</WhiteStorieCard>
                    {ads.length > 0 ? (
                        <>
                            <HorizontalAd adslot={ads[3].adslot} adsizes={ads[3].adsizes} adnumber={ads[3].adnumber} />
                        </>
                    ) : (<></>)
                    }
                </>
            )
        }
    }

    return (
        <>
            <Meta metadata={data.metadata} />
            {final_data()}

        </>
    )
}

export default Article;







