import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import WhiteCards from './WhiteCards';
import './Whitecard.css'
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from '../../WebButton/ViewMoreButton';

const StorieCard = (props) => {
    const { title, url, children } = props;
    return (
        <section className='whitecards'>
            <Container className='card-section'>
                <Row>
                    <div className='whitetitle'>
                        <h1>{title}</h1>
                        <ViewMoreButtonDesktop redirect={url} />
                    </div>
                    <div>

                    </div>
                </Row>
                <Row>
                    {
                        children.map((articaldata, index) => {
                            return <WhiteCards data={articaldata} key={index} />
                        })
                    }
                </Row>
                {/* <ViewMoreButtonMobile redirect={url} /> */}
            </Container>
        </section>
    );
}

export default StorieCard;