import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBzrnYvralPnN_Sc7X_7XsNaCj137aMcZk",
  authDomain: "mobilemasala-v2.firebaseapp.com",
  projectId: "mobilemasala-v2",
  storageBucket: "mobilemasala-v2.appspot.com",
  messagingSenderId: "187223718471",
  appId: "1:187223718471:web:72c8027b9ad9f8620ecf62",
  measurementId: "G-2T6SCLK222"

};

export const app = initializeApp(firebaseConfig);
export const FirebaseToken = getMessaging(app);
// const analytics = getAnalytics(app);