import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import BlackCards from './BlackCards';
import './BlackCards.css'
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from '../../WebButton/ViewMoreButton';

const StorieCard = (props) => {
    const { children, title, url } = props;
    return (
        <section className='blackcards'>
            <Container className='card-section'>
                <Row>
                    <div className='blacktitle'>
                        <h1>{title}</h1>
                        <ViewMoreButtonDesktop redirect={url} />
                    </div>
                </Row>
                <Row>
                    {
                        children.map((articaldata, index) => {
                            return <React.Fragment key={index}><BlackCards data={articaldata} /></React.Fragment>
                        })
                    }
                </Row>
                <ViewMoreButtonMobile redirect={url} />
            </Container>
        </section >
    );
}

export default StorieCard;