import React, { useReducer } from "react";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';


const ContactUs = () => {
    document.body.scrollTop = 0
    const [event, updateEvent] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, {
        fullname: "",
        email: "",
        phone: "",
        message: ""
    });

    const sendContactinfo = async () => {
        const data = await fetch('https://api.mobilemasala.com/user-api/contact-us', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(event)
        })
        const res = await data.json();
        if (res.status == 'SUCCESS') {
            alert('Contact details Send Successfully');
        } else {
            alert('Somthing Wrong');
        }
        window.location.reload();
    }
    return (
        <Container>
            <Row className="pt-5">
                <div className="whitetitle">
                    <h1>Contact Us</h1>
                </div>
                <Col lg={12} md={12} sm={12} xs={12} className='p-5'>
                    <div className="p-5" >
                        <Form >
                            <Form.Group className="mb-3">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" value={event.fullname} onChange={e => updateEvent({ fullname: e.target.value })} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email Id</Form.Label>
                                <Form.Control type="email" value={event.email} onChange={e => updateEvent({ email: e.target.value })} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number" value={event.phone} onChange={e => updateEvent({ phone: e.target.value })} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Meassage</Form.Label>
                                <Form.Control as="textarea" value={event.message} onChange={e => updateEvent({ message: e.target.value })} rows={3} />
                            </Form.Group>
                            <Button variant="dark" type="button" onClick={() => sendContactinfo()} >Submit</Button>
                        </Form>
                    </div>
                </Col>

            </Row>
        </Container>
    )
}

export default ContactUs;