import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import {Link} from 'react-router-dom';
import './ShortVideos.css';
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from '../WebButton/ViewMoreButton';
import { LineIcon } from 'react-share';

import { Link } from 'react-router-dom';

const ShortVideos = (props) => {
    const { children, title, url } = props;
    return (
        <>
            <section className='shortvideos'>
                <Container className='card-section'>
                    <Row>
                        <div className='whitetitle'>
                            {/* <h1>{title}</h1> */}
                            <h1>Shorts</h1>
                            <ViewMoreButtonDesktop redirect={url} />
                        </div>
                    </Row>
                    <Row>
                        {/* {
                            children.map((data, index) => { */}
                                {/* return ( */}
                                < Col lg={3} md={6} sm={6} xs={6} className='p-1'  >
                                        <Link to="https://mobilemasala.com/trailers/Bob-Marley-One-Love-Final-Trailer">
                                        <video loop={true} autoPlay="autoplay" muted="on">
                                            {/* <source src={data.video} type="video/mp4" /> */}
                                            <source src="https://beein.s3.ap-south-1.amazonaws.com/songs/audio/MMshorts/MMshort-1.mp4" type="video/mp4" />

                                        </video>
                                        </Link>
                                       
                                       
                                    </Col > 
                                    < Col lg={3} md={6} sm={6} xs={6} className='p-1'  >
                                        <Link to="https://mobilemasala.com/trailers/Bob-Marley-One-Love-Final-Trailer">
                                        <video loop={true} autoPlay="autoplay" muted="on">
                                            {/* <source src={data.video} type="video/mp4" /> */}
                                            <source src="https://beein.s3.ap-south-1.amazonaws.com/songs/audio/MMshorts/MMshort-1.mp4" type="video/mp4" />

                                        </video>
                                        </Link>
                                       
                                       
                                    </Col > < Col lg={3} md={6} sm={6} xs={6} className='p-1 border-1'  >
                                        <Link to="https://mobilemasala.com/trailers/Bob-Marley-One-Love-Final-Trailer">
                                        <video loop={true} autoPlay="autoplay" muted="on">
                                            {/* <source src={data.video} type="video/mp4" /> */}
                                            <source src="https://beein.s3.ap-south-1.amazonaws.com/songs/audio/MMshorts/MMshort-1.mp4" type="video/mp4" />

                                        </video>
                                        </Link>
                                       
                                       
                                    </Col > < Col lg={3} md={6} sm={6} xs={6} className='p-1 border-1'  >
                                        <Link to="https://mobilemasala.com/trailers/Bob-Marley-One-Love-Final-Trailer">
                                        <video loop={true} autoPlay="autoplay" muted="on">
                                            {/* <source src={data.video} type="video/mp4" /> */}
                                            <source src="https://beein.s3.ap-south-1.amazonaws.com/songs/audio/MMshorts/MMshort-1.mp4" type="video/mp4" />

                                        </video>
                                        </Link>
                                       
                                       
                                    </Col >

                                  
                                   
                                {/* ) */}
                            {/* })
                        } */}
                    </Row>
                    <ViewMoreButtonMobile redirect={url} />
                </Container>
            </section >
        </>
    );
}

export default ShortVideos;