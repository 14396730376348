
    import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import { Container, Row,Col } from "react-bootstrap";
import './Banner.css'
import { useAppContext } from '../Context/AppContext';
import { useContext } from 'react';

import {Link} from 'react-router-dom'

 function Banner(props) {
    // const { articleurl, categorytitle, categoryurl, date, image, title } = props.data;
    // const { BASE_PATH } = useContext(useAppContext);
    return (
        <section className="storieslider p-2">
         
            <Container >
             {/* <Row>
                <div className='whitetitle'>
                    <h1>E News  </h1>
                </div>
                </Row> */}

                <Row>
                <Col lg={6} md={6} sm={12} xs={12}  >
                  
 <div className=" h-100  w-100 p-2"> 
{/* <Link to="https://mobilemasala.com/movies/Dunki-Diaries---Shah-Rukh-Khan-feels-Vicky-Kaushal-is-his-nimbu-brother-here-is-why"> */}
<Link to="https://d3fn5rm3qk8k6v.cloudfront.net/songs/audio/Mdabdulpdf/1-MAR-24-MM.pdf">
<img src="/Pdf.webp" alt="banner" className="banner" />
</Link>
           
    </div>        
                 
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}  >
                  
                   <div className=" p-2"> 
<Link to="https://www.mobilemasala.com/film-gossip/Jab-We-Met-Veer-Zaara-Qismat-to-Premam-Every-film-re-releasing-in-Valentines-Film-Festival-get-ready-you-romantics">
<video loop={true} autoPlay="autoplay"  muted playsInline className="vid">
                                            {/* <source src={data.video} type="video/mp4" /> */}
                                            <source src="/MME1.mp4" type="video/mp4"   />

                                        </video>
</Link>
           
  </div>       
                 
                    </Col> 
                </Row>
              
            </Container>
        </section>
    );
}


export default Banner
