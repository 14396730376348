
import React, { useEffect, useState } from "react";
import Slider from "../Slider/Slider";
import HorizontalAd from "../Ads/HorizontalAd";
import HorizontalAdV2 from "../Ads/HorizontalAdV2";
import WhiteStorieCard from "../StorieCards/White/WhiteStorieCard";
import BlackStorieCard from "../StorieCards/Black/BlackStorieCard";
import StorieSlider from "../StorieSlider/StorieSlider";
import SlideCards from "../StorieCards/Slide/SlideCards";
import SlideCards2 from "../StorieCards/Slide/SlideCards2";
import Videos from "../Videos/Videos";
import RealtedArticel from "../RelatedArticel/RelatedArticel";
import Cookies from "js-cookie";
import ShortVideos from "../Videos/ShortVideos";
import Meta from "../Meta/Meta";
import StoriesCard from "../StorieSlider/StoriesCard";
import StorieSlider2 from "../StorieSlider/StorieSlider2";
import VerticalAd from "../Ads/VerticalAd";
import Banner from  "../Banner/Banner";
import Bannere from "../Banner/Bannere";
import Bannerh from "../Banner/Bannerh";

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [apidata, setApiData] = useState([]);

    const fetchData = async () => {
        const response = await fetch("https://api.mobilemasala.com/" + Cookies.get('lg') + "/homepage", {
            method: "POST"
        })
        const finaldata = await response.json()
        setApiData(finaldata.data)
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100" >
                <p>Loading</p>
            </div>
        )
    }

    if (!apidata.homedata.length > 0) {
        return (
            <><div className='d-flex align-items-center justify-content-center vh-100'>
                <p>No Data Found</p>
            </div></>
        )
    }

    const { metadata, homedata } = apidata;
    let bannerInserted = false;
    let bannerHInserted = false;
    let bannerEInserted = false;
    const layoutContent = (data) => {
        return homedata.map((category, index) => {
            switch (category.type) {
                case 'light-block':
                    return <React.Fragment key={index}> <WhiteStorieCard title={category.title} url={category.url}>{category.list}</WhiteStorieCard></ React.Fragment>;
        
                case 'dark-block':
                    if (!bannerEInserted && Cookies.get('lg') === 'en') {
                        bannerEInserted = true;
                        return (
                            <React.Fragment key={index}>
                                <Bannere />
                                <BlackStorieCard title={category.title} url={category.url}>{category.list}</BlackStorieCard>
                            </React.Fragment>
                        );
                    }
                    if (!bannerHInserted && Cookies.get('lg') === 'hi') {
                        bannerHInserted = true;
                        return (
                            <React.Fragment key={index}>
                                <Bannerh />
                                <BlackStorieCard title={category.title} url={category.url}>{category.list}</BlackStorieCard>
                            </React.Fragment>
                        );
                    }
                    if (!bannerInserted && Cookies.get('lg') === 'tl') {
                        bannerInserted = true;
                        return (
                            <React.Fragment key={index}>
                                <Banner />
                                <BlackStorieCard title={category.title} url={category.url}>{category.list}</BlackStorieCard>
                            </React.Fragment>
                        );
                    }
                   
                    return <React.Fragment key={index}><BlackStorieCard title={category.title} url={category.url}>{category.list}</BlackStorieCard></ React.Fragment>;

                case 'main-slider':
                    return <React.Fragment key={index}><Slider list={category.list} /> </ React.Fragment>;

                case 'article-slider':
                    return <React.Fragment key={index}> <SlideCards title={category.title} url={category.url}>{category.list}</SlideCards></ React.Fragment>;

                case 'motion-slider':
                    const storieimages = category.list.map((storiedata, i) => {
                        return {
                            key: i,
                            content: <StoriesCard carddata={storiedata} />
                        }
                    })
                    return <React.Fragment key={index}> <StorieSlider cards={storieimages}
                        width="90%"
                        margin="0 auto"
                        offset={2}
                        showArrows={false} title={category.title} url={category.url} /></ React.Fragment>;

                case 'number-slider':
                    return <React.Fragment key={index}> <SlideCards2 title={category.title} url={category.url}>{category.list}</SlideCards2></ React.Fragment>;

                case 'photo-stories':
                    return <React.Fragment key={index}> <StorieSlider2 title={category.title} url={category.url}>{category.list}</StorieSlider2></ React.Fragment>;

                case 'youtube-videos':
                    return <React.Fragment key={index}> <Videos title={category.title} url={category.url}>{category.list}</Videos></ React.Fragment>;

                case 'trending-block':
                    return <React.Fragment key={index}> <RealtedArticel title={category.title} url={category.url}>{category.list}</RealtedArticel></ React.Fragment>;

                case 'short-videos':
                    return <React.Fragment key={index}> <ShortVideos title={category.title} url={category.url}>{category.list}</ShortVideos></ React.Fragment>;

                // case 'horizontal-ad':
                //     return <React.Fragment key={index}><HorizontalAdV2 adslot={category.adslot} adsizes={category.adsizes} adnumber={category.adnumber} desktop_adslot={category.desktop_adslot} desktop_adsizes={category.desktop_adsizes} /></ React.Fragment>;

                // case 'vertical-ad':
                //     return <React.Fragment key={index}><VerticalAd adslot={category.adslot} adsizes={category.adsizes} adnumber={category.adnumber} /></ React.Fragment>;

                default:

                    break;
            }
        })
    }
    return (
        <>
            <Meta metadata={metadata} />
            {layoutContent(apidata.homedata)}
        </>
    )
}


export default Home;


