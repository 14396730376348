import React, { useEffect, useState } from 'react';
import { Col, Container, Pagination, Row } from 'react-bootstrap';
import { useLocation, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import Meta from '../Meta/Meta';
import HorizontalAd from '../Ads/HorizontalAd';
import './YoutubeVIdeosCategory.css';
import YoutubeVideosCard from './YoutubeVideosCard';

const YoutubeVIdeosCategory = () => {
    // if (!Cookies.get('lg')) {
    //     Cookies.set('lg', 'en');
    // }
    const { pathname } = useLocation();
    const categoryname = pathname.replace(/\//g, '');

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [urlpg, setUrlPg] = useState();
    const [searchParams, setSearchParams] = useSearchParams()
    const fetchData = async (pagenumber = '1') => {
        const response = await fetch("https://api.mobilemasala.com/" + Cookies.get('lg') + "/videos?name=" + categoryname + "&page=" + pagenumber, {
            method: "POST"
        });
        const finaldata = await response.json();
        setData(finaldata.data);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100" >
                <p>Loading</p>
            </div>
        )
    }

    const { metadata, categorydata, pagination, pagetitle } = data;
    const { paginationurls, paginationnumber } = pagination;

    const handlePagination = (pnumber) => {
        setUrlPg(pnumber);
        fetchData(pnumber);
        setSearchParams(`?${new URLSearchParams({ page: pnumber })}`);
        window.scrollTo(0, 0)
    }

    return (
        <>
            <Meta metadata={metadata} />

            <section className='youtubevideoscategory'>
                <Container>
                    <Row>
                        <div className='whitetitle '>
                            <h1>{pagetitle}</h1>
                        </div>
                    </Row>
                    {categorydata.length > 0 ? (
                        categorydata.map((category, index) => {
                            return (
                                <Row key={index}>
                                    {
                                        category.map((singlecard, index) => {
                                            return <YoutubeVideosCard data={singlecard} title={pagetitle} key={index} />
                                        })
                                    }
                                    <HorizontalAd />
                                </Row>
                            )
                        })
                    ) : (
                        <><div className='d-flex align-items-center justify-content-center vh-100'>
                            <p>No Data Found</p>
                        </div></>
                    )
                    }
                    <Row>
                        <Col className='d-flex justify-content-center pg'>
                            <Pagination size="sm" >
                                {pagination != null && paginationurls.map((data, value) => {
                                    for (let key in data) {
                                        return (
                                            <Pagination.Item key={value} active={paginationnumber === data[key]} onClick={() => handlePagination(data[key])}>
                                                {key}
                                            </Pagination.Item>
                                        )
                                    }
                                })
                                }
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    );
}

export default YoutubeVIdeosCategory;







