import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import { Container, Row } from "react-bootstrap";
import './StorieSlider.css'
import ViewMoreButtonDesktop, { ViewMoreButtonMobile } from "../WebButton/ViewMoreButton";

export default function StorieSlider(props) {
    const table = props.cards.map((element, index) => {
        return { ...element, onClick: () => setGoToSlide(index) };
    });

    const [offsetRadius, setOffsetRadius] = useState(1);
    const [showArrows, setShowArrows] = useState(false);
    const [goToSlide, setGoToSlide] = useState(null);
    const [cards] = useState(table);

    useEffect(() => {
        setOffsetRadius(props.offset);
        setShowArrows(props.showArrows);
    }, [props.offset, props.showArrows]);

    return (
        <section className="storieslider pb-5">
            <Container>
                <Row>
                    <div className="whitetitle">
                        <h1>{props.title}</h1>
                        <ViewMoreButtonDesktop redirect={props.url} />
                    </div>
                </Row>
                <Row>
                    <div className="storieslidersection mt-3" style={{ width: props.width, margin: props.margin }} >
                        <Carousel
                            slides={cards}
                            goToSlide={goToSlide}
                            offsetRadius={offsetRadius}
                            showNavigation={showArrows}
                            animationConfig={config.gentle}
                        />
                    </div>
                </Row>
                <Row style={{ 'marginTop': '40px' }}>
                    <ViewMoreButtonMobile redirect={props.url} />
                </Row>
            </Container>
        </section>
    );
}
